<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.title.allIncome')">
      <!-- <div>
        {{ $t('message.machineId') }} : (options)
        <multi-list-select :list="machineData" option-value="machineId" option-text="name" :selected-items="selectedMachine" placeholder="All" @select="onSelectMachine"></multi-list-select>
      </div>-->

      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-2 my-1">
          {{ $t("message.year") }} :
          <br />
          <b-form-select v-model="timeSel" :options="language== 'th'? timeOptTH: timeOptEN" v-on:change="selectTime" />
        </div>

        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 my-1">
          {{ $t('message.machineIdFarm') }} :
          <model-list-select :list="machineData" v-model="filters.machineId" option-value="machineId" option-text="name" placeholder="เลือกรหัสร้าน"></model-list-select>
          <!-- <multi-list-select :list="machineData" option-value="machineId" option-text="name" :selected-items="selectedMachine" :placeholder="$t('message.all')" @select="onSelectMachine"></multi-list-select> -->
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-2 my-1">
          {{ $t('message.product') }} : (options)
          <multi-list-select :list="ProductItems" option-value="id" option-text="name" :selected-items="selectedProduct" placeholder="All" @select="onSelect"></multi-list-select>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 my-1">
          {{ $t('message.date') }} :
          <br />
          <div class="table-responsive">
            <date-time-picker v-model="filters.date" type="datetime" width="100"></date-time-picker>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-12 col-lg-6 col-xl-2">
          <br/>
          <b-button v-on:click="searchFn()" class="col-12 col-sm-6 col-md-3 col-lg-5 " variant="primary">
            <i class="fa fa-search"></i>
            &nbsp;{{ $t('message.search') }}
          </b-button>&nbsp;
          <b-btn v-on:click="exportFn()" class="col-12 col-sm-6 col-md-3 col-lg-5"  variant="success">
            <i class="icon-docs"></i>
            &nbsp;{{
            $t("message.export")
            }}
          </b-btn>
        </div>
      </div>

      <br />
      <hr />

      <div class="wrapper">
        <br />
        <br />
        <!-- ข้อมูลการเติมเงิน -->

        <div class="row">
          <div class="col-12 col-md-7">
            <b>{{ $t('message.top-upInformation') }}</b>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <template>
              <div class="table-responsive">
                <Table :columns="columnsTopup" :data="dataTopup" show-summary :summary-method="topupSummary"></Table>
              </div>
            </template>
          </div>
        </div>

        <!-- รายละเอียดรายได้ -->
        <br />
        <p>
          <b>{{ $t('message.incomeDetails') }}</b>
        </p>
        <div class="row">
          <div class="col-12">
            <template>
              <div class="table-responsive">
                <Table row-key="productId" :columns="columnsSales" :data="dataSales" :load-data="handleLoadData" show-summary :summary-method="salesSummary">
                  <template slot-scope="{ row }" slot="productName">
                    <span v-if="row.paymentType">&emsp;&emsp;{{row.paymentType}}</span>
                    <strong v-else>{{ ProductItems.find(val => val.id == row.productId).productName }}</strong>
                  </template>
                  <template slot-scope="{ row }" slot="totalSales">
                    <span v-if="row.paymentType">{{row.total | formatNumber}}</span>
                    <strong v-else>{{ row.payments.reduce((prev, cur) => prev + cur.total, 0) | formatNumber}}</strong>
                  </template>
                  <template slot-scope="{ row }" slot="count">
                    <span v-if="row.paymentType">{{row.count}}</span>
                    <strong v-else>{{row.count}}</strong>
                  </template>
                  <template slot-scope="{ row }" slot="action">
                    <Button v-if="!row.paymentType" type="primary" size="small" style="margin-right: 5px" @click="detail(row.productId)">View</Button>
                  </template>
                </Table>
              </div>
            </template>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment'
import FileSaver from 'file-saver'
import axios from 'axios'
import webServices from '../../script'
import { ModelListSelect, MultiListSelect } from '../../custom_modules/search-select'

// import DateTimePicker from '../../components/DateTimePicker/DateTimePicker.vue'
import DateTimePicker from '../../components/DateTimePicker/Custom.vue'
// import DateTimePicker from '../../components/DateTimePicker_custom.vue'

//https://stackoverflow.com/questions/44538110/how-to-format-numbers-in-vuejs
var numeral = require('numeral')

Vue.filter('formatNumber', function (value) {
  return numeral(value).format('0,0') // displaying other groupings/separators is possible, look at the docs
})

export default {
  name: 'SalesByUser',
  components: {
    DateTimePicker,
    ModelListSelect,
    MultiListSelect
  },
  data() {
    return {
      api: {},
      timeSel: 0,
      timeOptTH: [
        { value: 0, text: '2022 ถึง ปีปัจจุบัน' },
        { value: 1, text: '<2022'}
      ],
      timeOptEN: [
        { value: 0, text: '2022 To This Year' },
        { value: 1, text: '<2022'}
      ],
      language: window.localStorage.getItem("language"),
      ProductItems: [],
      selectedProduct: [],
      selectedMachine: [],
      machineData: [],
      filters: {
        // date: [moment().startOf('day').subtract(1, 'months').toDate(), moment().endOf('day').toDate()]
        date: [moment().startOf('day').toDate(), moment().endOf('day').toDate()],

      },
      filters_export: {},
      dataTopup: [],
      columnsTopup: [
        {
          title: this.$t('message.paymentType'),
          align: 'center',
          key: 'paymentType',
          minWidth: 100
        },
        {
          title: this.$t('message.amount'),
          align: 'right',
          key: 'count',
          minWidth: 100
        },
        {
          title: this.$t('message.allRevenue'),
          align: 'right',
          key: 'total',
          minWidth: 100,
          render: (h, params) => {
            return h('span', Vue.filter('formatNumber')(params.row.total))
          }
        }
      ],
      dataSales: [],
      columnsSales: [
        {
          title: this.$t('message.productId'),
          key: 'productId',
          minWidth: 100,
          tree: true
        },
        {
          title: this.$t('message.productName'),
          key: 'productName',
          minWidth: 200,
          slot: 'productName'
        },
        {
          title: this.$t('message.count'),
          align: 'right',
          key: 'count',
          minWidth: 100,
          slot: 'count'
        },
        {
          title: this.$t('message.totalSales'),
          align: 'right',
          key: 'total',
          minWidth: 100,
          slot: 'totalSales'
        },
        {
          title: this.$t('message.detail'),
          align: 'center',
          minWidth: 150,
          slot: 'action'
        }
      ],
      file_name: 'Report',
      branch: '',
      startDate: '',
      endDate: '',
    }
  },
  methods: {
    selectTime(value) {
      if (value > 0) {
        this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
      } else {
        this.api.defaults.baseURL = this.axios.defaults.baseURL
      }
    },
    getMachine() {
      this.api
        .get(`/machines/list?`)
        .then(res => {
          this.machineData = res.data
        })
        .catch(err => {
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
          this.$toast.error({
            title: 'ERROR',
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          })
          console.log('error @machine')
          console.log(err)
        })
    },
    getProduct() {
      this.api
        .get(`/products/list`)
        .then(res => {
          // console.log(res.data)
          this.ProductItems = res.data
        })
        .catch(err => {
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText
          }
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(errorText)
          })
          console.log('error @get product ORDER')
          console.log(err)
        })
    },

    showAlert_ChooseOneBranch() {
      if(window.localStorage.getItem("language") == 'th'){
        var title = "โปรดเลือกช่วงเวลาใหม่อีกครั้ง"
        var text = 'ไม่สามารถเลือกเดือนได้มากกว่า 12 เดือน'
        var confirmButton = "ปิด"
      }else{
        var title = "Please select a new time again"
        var text = "Cannot select more than 12 months."
        var confirmButton = "Close"
      }

      this.$swal({
        title: title,
        text: text,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: confirmButton
      })
    },

    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },

    searchFn() {
      if (!this.filters.machineId) {
        this.$toast.error({
          title: 'ERROR',
          message: 'กรุณาเลือกสาขา'
        })

        return
      }
      var localStorageUser =  JSON.parse(window.localStorage.getItem("users") )
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.filters.userName = localStorageUser.userName
      this.filters.userRole = localStorageUser.role
      this.filters.userType = localStorageUser.type
      this.filters.userConfiglanguage = localStorageUser.config.language
      this.filters.datetimeRequest = datetimeRequest

      this.$Progress.start()
      const params = {
        ...this.filters,
        from: this.filters.date[0],
        to: this.filters.date[1]
      }

      this.filters_export = params

      delete params.date

      var dateStart = new Date(this.filters.date[0])
      var dateEnd = new Date(this.filters.date[1])

      // console.log("monthDiff", this.monthDiff(dateStart, dateEnd))

      if(this.monthDiff(dateStart, dateEnd) < 12){

        this.api
        .get(`/report/daily`, { params })
        .then(res => {
          this.$Progress.finish()
          this.dataSales = res.data.sales.rows.map(v => ({ ...v, _loading: false, children: [] }))
          // this.dataSales = res.data.sales.rows.map((data) => {
          //   return {
          //     ...data,
          //     "_loading": false,
          //     "children": data.payments,
          //     // "_showChildren": false
          //   }
          // })
          this.dataTopup = res.data.topup.rows
          // console.log(' this.dataSales ',    this.dataSales )
        })
        .catch(err => {
          this.$Progress.fail()
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
          this.$toast.error({
            title: 'ERROR',
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          })
          console.log('error @machine')
          console.log(err)
        })
      }else{
        console.log("เลือกเดือนได้สูงสุด 12 เดือน")
        this.showAlert_ChooseOneBranch() // เลือกเดือนได้สูงสุด 12 เดือน
      }
    },
    exportFn() {
      this.$Progress.start()
      // this.onSelectMachine(this.filters.machineId)

      for (let i = 0; i < this.machineData.length; i++) {
        if( this.filters_export.machineId === this.machineData[i].machineId){
          const machineName =  this.machineData[i].name
          this.filters_export.name = machineName.substr(9).replaceAll('\\r\\n', '')
        }
      }


      const params = this.filters_export

      delete params.date

      this.api({
        url: `/report/daily/download`, // File URL Goes Here
        method: 'GET',
        params,
        responseType: 'blob'
      }).then(response => {
        // FileSaver.saveAs(response.data, 'filename.xlsx')
        FileSaver.saveAs(response.data, `Report_Total_Income_${this.filters_export.machineId}_date_${moment(this.filters_export.from).format('YYYY-MM-DD')}_to_${moment(this.filters_export.to).format('YYYY-MM-DD')}.xlsx`)
        this.$Progress.finish()
      })
    },
    detail(productId) {
      const params = {
        productId: productId,
        from: moment(this.filters.date[0]).format('YYYY-MM-DD HH:mm:ss'),
        to: moment(this.filters.date[1]).format('YYYY-MM-DD HH:mm:ss')
      }

      if (this.filters.machineId) {
        params.machineId = this.filters.machineId
      }
      // console.log(params)

      this.$router.push({ path: '/orders', query: params })
    },
    onSelect(items) {
      // console.log('onSelectMachine', items)
      this.selectedProduct = items
      this.filters.productId = this.selectedProduct.map(data => data.id)
    },
    onSelectMachine(items) {
      // console.log('onSelectMachine', items)
      this.selectedMachine = items
      this.filters.machineId = this.selectedMachine.map(data => data.machineId)
      this.filters.name = this.selectedMachine.map((data) => data.name.substr(9).replaceAll('\\r\\n', '') )
    },

    salesSummary({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key

        if (index === 0) {
          sums[key] = {
            key,
            value: ''
          }
          return
        }

        if (index === 1) {
          sums[key] = {
            key,
            value: this.$t('message.summary')
          }
          return
        }

        const values = data.map(item => Number(item[key]))
        if (!values.every(value => isNaN(value))) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[key] = {
            key,
            value: Vue.filter('formatNumber')(v)
          }
        } else {
          sums[key] = {
            key,
            value: ''
          }
        }
      })

      // console.log(sums)

      return sums
    },
    topupSummary({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key

        if (index === 0) {
          sums[key] = {
            key,
            value: this.$t('message.summary')
          }
          return
        }

        const values = data.map(item => Number(item[key]))
        if (!values.every(value => isNaN(value))) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[key] = {
            key,
            value: Vue.filter('formatNumber')(v)
          }
        } else {
          sums[key] = {
            key,
            value: ''
          }
        }
      })

      // console.log(sums)

      return sums
    },
    handleLoadData(item, callback) {
      callback(item.payments)
    }
  },
  mounted() {
    this.api = axios.create({
      baseURL: this.axios.defaults.baseURL
    })

    this.api.defaults.headers.common['Authorization'] = this.api.defaults.headers.common['Authorization']

    this.getProduct()
    this.getMachine()
  }
}
</script>

<style>
@import './styleRe.css';
</style>
